import CaptchaApi from './CaptchaApi';
import compareTo from './compareTo';
import nepBindMarkup from './nepBindMarkup';
import nepCaptcha from './nepCaptcha';
import queryByCache from './queryByCache';
import queryOne from './queryOne';
import showAllWarnings from './showAllWarnings';
import stringToNumber from './stringToNumber';
import trustAsHtml from './trustAsHtml';

const registerNgUtils = ngModule => {
  CaptchaApi(ngModule);
  compareTo(ngModule);
  nepBindMarkup(ngModule);
  nepCaptcha(ngModule);
  queryByCache(ngModule);
  queryOne(ngModule);
  showAllWarnings(ngModule);
  stringToNumber(ngModule);
  trustAsHtml(ngModule);
};

export default registerNgUtils;
