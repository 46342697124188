// Service for getting participants.
//
// Usage:
//
// var participant = Participant.get({id: participantId});
// var matching = Participant.query({name: 'smarcher', organization_id: 'ISIS'})

const participantService = ngModule => {
  ngModule.service('Participant', function Participant(
    $resource,
    hostingDomain,
  ) {
    const ParticipantResource = $resource(
      // Important Notes:
      // GET /api/participants/:id will always respond with 403
      // GET /api/participants requires params `name` and `organization_id`
      // POST /api/participants ditto

      `//${hostingDomain}/api/participants/:id`,
      { id: '@uid' },
    );

    return ParticipantResource;
  });
};

export default participantService;
