/**
 * @coreapi
 * @module url
 */ /** for typedoc */
export * from './interface';
export * from './urlMatcher';
export * from './urlMatcherFactory';
export * from './urlRouter';
export * from './urlRule';
export * from './urlService';
