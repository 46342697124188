import moment from 'moment';

(function () {
  'use strict';

  /**
   * @ngdoc component
   * @name neptuneApp.component:nepMonitorSurveyTask
   * @description
   *   Shows various messages and lets users mark survey complete, conditional
   *   on having some participation.
   */

  window.ngModule.component('nepMonitorSurveyTask', {
    bindings: {

      /**
       * Parent can bind to this event. Called when survey has enough
       * participation to be completable by the org admin.
       * @type {Function}
       */
      setCompletable: '&',

      /**
       * Parent can bind to this event. Called to trigger an update/PUT of
       * the task.
       * @type {Function}
       */
      taskComplete: '&',

      /**
       * The task resource.
       * @type {Object}
       */
      task: '<',

      /**
       * Cohort (not a resource)
       * @type {Object}
       */
      cohort: '<',
    },
    transclude: true,
    controller: MonitorSurveyTaskController,
    template: require('./monitor_survey_task.view.html'),
  });

  function MonitorSurveyTaskController(
    $scope,
    Survey,
    Checkpoint,
    Task,
    Participation,
  ) {
    const vm = this;

    vm.$onInit = function () {
      // Until the data comes in, assume the task cannot be completed i.e.
      // is invalid.
      vm.setCompletable({ surveyCompletable: false, error: null });
    };

    vm.$onChanges = function (changes) {
      const previouslyUnset =
        changes.cohort &&
        changes.cohort.currentValue &&
        (!changes.cohort.previousValue ||
          Object.keys(changes.cohort.previousValue).length === 0);
      if (previouslyUnset) {
        initCohort();
      }
    };

    $scope.$watch(
      function valueToWatch() {
        return vm.task.status;
      },
      function onChange() {
        foreverReadiness();
      },
    );

    function initCohort() {
      // Is participation open according to the current date and the date
      // the cohort opens? N.B. ISO strings are well ordered
      const today = new Date();
      vm.openByDate =
        vm.cohort.participationOpenDate && today > vm.cohort.participationOpenDate;

      // Is participation open according to the status of the survey (which
      // is also stored in the task attachment)?
      vm.openByReadiness =
        vm.task.attachment && vm.task.attachment !== Survey.NOT_READY_STATUS;

      foreverReadiness();
    }

    function foreverReadiness() {
      // Note that at this point in the logic, we used to check the
      // count of responses in the current survey, and only make the task ready
      // if there was some participation, and then the user would have to click
      // a button to signify they were ready to move on. Since 2023, we don't
      // we're just leaving things open; there's no button to click. To avoid
      // leaving users with an impossible task we cause this task to become
      // complete as soon as (1) the dates are right and (2) previous tasks are
      // done.
      if (vm.openByDate && vm.openByReadiness) {
        vm.task.status = Task.COMPLETE_STATUS;
        vm.task.attachment = Task.COMPLETE_STATUS;
        vm.taskComplete();
      }
    }

    function placeParticipationOnScope(participation) {
      vm.participation = participation;
      const finishedRow = participation.find(row => row.value === '100');
      vm.numFinished = finishedRow ? parseInt(finishedRow.n, 10) : 0;
      return participation;
    }
  }
})();
