const nepCaptcha = ngModule => {
  ngModule.directive('nepCaptcha', (CaptchaApi) => {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        element.attr('id', attrs.nepCaptcha);
        element.addClass('captcha');
        CaptchaApi.register(attrs.nepCaptcha);
      }
    };
  });
};

export default nepCaptcha;
