(function () {
  'use strict';

  window.ngModule.component('uiProgramSelectPromptCard', {
    template: `
        <ui-info-card>
          <div><i class="fa fa-3x fa-hand-o-right"></i></div>
          <div>Select a Program<br />or Search for an Organization</div>
        </ui-info-card>
      `,
  });
})();
