/* global hcaptcha */

// Layer between global hcaptcha JS api and Neptune, mostly to translate the
// awkward global-scope rendering and widget id system they use, where the
// widget id is determined at run time. This lets us transact in pre-determined
// DOM element ids.

const CaptchaApi = ngModule => {
  ngModule.service('CaptchaApi', function ($window, hcaptchaSiteKey) {
    const captchas = {}; // registry
    let hcaptchaLoaded = false;

    /* jshint validthis: true */

    this.render = (elementId) => {
      let widgetId = captchas[elementId];
      if (widgetId === null) {
        // Capture run-time defined widet IDs.
        widgetId = hcaptcha.render(elementId, { sitekey: hcaptchaSiteKey });
        captchas[elementId] = widgetId;
      }
    }

    // Elements with the directive `nep-captcha` on them call this so we can
    // render them when the hcaptcha lib is loaded.
    this.register = (elementId) => {
      captchas[elementId] = null;

      if (hcaptchaLoaded) {
        this.render(elementId);
      } // else window.renderCaptchas will render it when the lib is loaded
    };

    // Pass through to hcaptcha API methods, but with element IDs.

    this.getResponse = (elementId) => {
      var widgetId = captchas[elementId];
      return hcaptcha.getResponse(widgetId);
    };

    this.reset = (elementId) => {
      var widgetId = captchas[elementId];
      hcaptcha.reset(widgetId);
    };

    this.setGlobalCallback = () => {
      // Invoked from hcaptcha script tag.
      $window.renderCaptchas = () => {
        angular.forEach(captchas, (widgetId, elementId) => {
          this.render(elementId);
        });
        hcaptchaLoaded = true;
      };
    };
  });
};

export default CaptchaApi;
